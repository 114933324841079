.doc {
  padding: 1rem !important;
}

.doc > h1,
.doc > h2,
.doc > h3,
.doc > h4,
.doc > h5,
.doc > h6,
.doc > p {
  margin: 0px 0px 15px 0px;
}

.doc > p {
  line-height: 1.5em;
}

.doc > pre {
  line-height: 21px;
  border-radius: 3px;
  text-shadow: none;
}

.doc > ul {
  list-style-type: disc;
  padding-left: 20px;
}

ol {
  padding-left: 1rem;
}
