.back-button,
.back-button:visited,
.back-button:active {
  border: none;
  background: none;
  outline: none;
  margin-right: 0.8em;
  box-shadow: none;
  padding: 0;
  min-width: 0;
  line-height: 0;
  text-decoration-skip-ink: auto;
  text-decoration: none;
  border-style: hidden;
  -webkit-appearance: unset;
  box-sizing: border-box;
}

.back-button > i {
  font-size: 26px;
  color: #343a40;
}

.back-button:focus {
  outline: 0;
}

.back-button:hover {
  cursor: pointer;
}
